.field-input {
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 14px;
}

.field-input:focus {
  border-color: #3182ce;
}

.field-input-error {
  color: red;
  margin-top: 5px;
  font-size: 12px;
}
