.notary-table{
    position: relative;
}

.notary-table th:nth-of-type(1),.notary-table td:nth-of-type(1) {
    position: sticky;
    left: 0;
    width: 80px;
    background-color: #fff;
}

.notary-table th:nth-of-type(2),.notary-table td:nth-of-type(2) {
    position: sticky;
    left: 40px;
    width: 100px;
    background-color: #fff;
}

.notary-table th:last-child,.notary-table td:last-child {
    position: sticky;
    right: 0;
    width: 100px;
    background-color: #fff;
}