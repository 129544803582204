/* Linear loader */

.linear-activity {
  overflow: hidden;
  width: 100%;
  height: 4px;
  background-color: #b3e5fc;
  /* margin: 20px auto; */
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #03a9f4;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #4fc3f7;
  animation: indeterminate_second 1.5s infinite ease-in;
}

.label-top {
  transform: translateY(10px);
  font-weight: "700";
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

.loader {
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.pagination {
  padding-top: 1rem;
  text-align: center;
}

.pagination > ul {
  padding: 0;
  margin: 0;
}

.pagination li {
  display: inline-block;
}

.pagination li > a {
  padding: 4px 6px;
  margin: 1px;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #d8eef5;
  border-radius: 5px;
  min-width: 35px;
  text-align: center;
  font-size: 14px;
}

.pagination li > a:focus {
  outline: none;
}

.pagination li > a:hover {
  color: #fff;
  /* background-color: #d5ad36; */
  background-color: #2d3748;
  border-color: #2d3748;
}

.pagination > li.active > a {
  color: #fff;
  /* background-color: #d5ad36; */
  background-color: #2d3748;
  border-color: #2d3748;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

svg.star-svg {
  display: inline;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
