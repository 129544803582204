@import url("https://fonts.googleapis.com/css?family=Open+Sans|Rock+Salt|Shadows+Into+Light|Cedarville+Cursive");

@font-face {
  font-family: myFirstFont;
  /* src: url(sansation_bold.woff); */
  src: url("../../assets/fonts/CreattionDemo.otf");
  font-weight: bold;
}

@font-face {
  font-family: mySecondFont;
  src: url("../../assets/fonts/Holimountswash.otf");
  font-weight: bold;
}

.sig1 {
  font-family: "Shadows Into Light", cursive;
  font-size: 1.8em;
}
.sig2 {
  font-family: "Rock Salt", cursive;
}
.sig3 {
  font-family: "Cedarville Cursive", cursive;
  font-size: 1.8em;
}
.sig4 {
  font-family: "myFirstFont", cursive;
  font-size: 1.8em;
}
.sig5 {
  font-family: "mySecondFont", cursive;
  font-size: 1.8em;
}

.ps-menu-label {
  font-size: 16px;
  font-weight: bold;
}

.ps-menuitem-root {
  margin-bottom: 20px;
}

.newSidebar {
  /* position: relative; */
  /* margin-top: -5.9vh; */
}

aside.ps-sidebar-root,
aside.ps-sidebar-root.ps-collapsed {
  height: 100%;
  transition: width, left, right, 0.3s;
}

.newSidebar .ps-menu-root .ps-submenu-content ul li {
  margin-bottom: 0;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.sidebar-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a9a9a9;
  border-radius: 10px;
}

/* Handle */
.sidebar-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.sidebar-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (min-width: 1920px) {
  .newSidebar {
    /* margin-top: -5vh; */
  }
}
